import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';

const items = [
    {
        title: 'Technikai segítség',
        numberOfCourses: '7/24 órás ügyelet',
        icon: 'icon-Setting',
        link: '#'
    },
    {
        title: 'Személyes kapcsolattartó',
        numberOfCourses: 'Sserzōdéskötés után kijelöljük',
        icon: 'icon-Headphone',
        link: '#'
    },
    {
        title: 'Teljesen testreszabható',
        numberOfCourses: 'Egyénre szabható kimutatások',
        icon: 'icon-Pencil',
        link: '#'
    },
    {
        title: 'Business Management',
        numberOfCourses: 'Nem csupán szoftvereket adunk',
        icon: 'icon-Class',
        link: '#'
    },
    {
        title: 'Webshop fejlesztés',
        numberOfCourses: '15+ év fejlesztés terén',
        icon: 'icon-Setting',
        link: '#'
    },
    {
        title: 'Könnyű használat',
        numberOfCourses: 'Egyszerű használat bárkinek',
        icon: 'icon-Mind',
        link: '#'
    },
    {
        title: 'Digitális marketing',
        numberOfCourses: 'Összes platformját ránkbízhatja',
        icon: 'icon-Headphone',
        link: '#'
    },
    {
        title: 'Forgalmmi elemések',
        numberOfCourses: 'Részletesebb statisztikák',
        icon: 'icon-Microscope',
        link: '#'
    },
    {
        title: 'Integrációk tárháza',
        numberOfCourses: 'Szabadon integrálható modulok',
        icon: 'icon-Fitness',
        link: '#'
    }
];

const CategoryThree = ( { wrapperClass, styleClass } ) => {
    return (
        <div className={ `row ${ wrapperClass || 'g-5 mt--25' }` }>
            { items.map( ( data , i ) => (
                <ScrollAnimation
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    className={ `col-lg-4 col-md-6 col-12 ${ styleClass ? styleClass : '' }` }
                    animateOnce={ true }
                    key={ i }
                >
                    <div className="service-card service-card-5">
                        <div className="inner">
                            <div className="icon">
                                <i className={ data.icon }></i>
                            </div>
                            <div className="content">
                                <h6 className="title"><a href={ data.link }>{ data.title }</a></h6>
                                <p className="description">{ data.numberOfCourses }</p>
                            </div>
                        </div>
                    </div>
                </ScrollAnimation>
            ) ) }
        </div>
    )
}

export default CategoryThree;
