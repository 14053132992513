import React from "react";
import {useState} from "react";
import {Link} from "react-router-dom";
import Nav from "./Nav";
import HeaderSticky from "./HeaderSticky";
import ResponsiveMenu from "./ResponsiveMenu";
import packageJson from "../../../package.json";
import {useHappyContext} from "../../context/happy_context.js";
import {Modal} from "react-bootstrap";
import Lottie from "react-lottie";
import * as animationData from "../../assets/animations/sign-up.json";
import axios from "axios";
import Swal from "sweetalert2";

const HeaderOne = ({styles, disableSticky}) => {
    const {isLoggedIn, setIsLoggedIn} = useHappyContext();
    const [offcanvasShow, setOffcanvasShow] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const [email, setEmail] = useState(undefined);

    const [showLoginModal, setShowLoginModal] = React.useState(false);

    const onCanvasHandler = () => {
        setOffcanvasShow((prevState) => !prevState);
    };

    const onSearchHandler = () => {
        setSearchPopup((prevState) => !prevState);
    };

    if (searchPopup) {
        document.body.classList.add("search-popup-active");
    } else {
        document.body.classList.remove("search-popup-active");
    }

    const sticky = HeaderSticky(200);
    const classes = sticky ? "sticky" : "";
    const stickyStatus = disableSticky ? "" : " header-sticky";
    return (
        <React.Fragment>
            <Modal
                show={showLoginModal}
                onHide={() => setShowLoginModal(false)}
                centered
                size="lg"
            >
                <Modal.Body>
                    <div
                        className="eduvibe-contact-us edu-contact-us-area bg-color-white"
                        style={{padding: "2rem"}}
                    >
                        <div
                            className="container eduvibe-animated-shape"
                            style={{maxWidth: "100%", width: "100%"}}
                        >
                            <div className="row g-5">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h5 className="title">Bejelentkezés</h5>
                                        <small className="pre-title">
                                            Egyszerűen csak adja meg a regisztrálásnál megadott email
                                            címet
                                        </small>
                                    </div>
                                </div>
                            </div>
                            <div className="row g-5 mt--20 d-flex align-items-center">
                                <div className="col-6">
                                    <Lottie
                                        options={{
                                            loop: true,
                                            autoplay: true,
                                            animationData: animationData,
                                            rendererSettings: {
                                                preserveAspectRatio: "xMidYMid cover",
                                            },
                                        }}
                                        width={"35rem"}
                                        height={"35rem"}
                                    />
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <input
                                            autoFocus
                                            autoCapitalize
                                            type="email"
                                            className="form-control form-control-lg"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email cím"
                                            required
                                        />
                                    </div>
                                    <div className="form-group d-flex justify-content-center">
                                        <button
                                            className="rn-btn edu-btn"
                                            style={{margin: "0 auto"}}
                                            onClick={() => {
                                                axios({
                                                    method: "post",
                                                    url: `${window.api}/company/auth/login`,
                                                    headers: {"Content-Type": "application/json"},
                                                    data: {
                                                        email: email,
                                                    },
                                                })
                                                    .then((res) => {
                                                        if (res.data.success) {
                                                            setIsLoggedIn(true);
                                                            localStorage.setItem("email", email);
                                                            setShowLoginModal(false);
                                                        } else
                                                            Swal.fire({
                                                                title: "Sikertelen bejelentkezés!",
                                                                confirmButtonColor: "#f1b44c",
                                                            });
                                                    })
                                                    .catch((err) => {
                                                        console.log(err);
                                                        Swal.fire({
                                                            title: "Sikertelen bejelentkezés!",
                                                            confirmButtonColor: "#f1b44c",
                                                        });
                                                    });
                                            }}
                                        >
                                            Belépés
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <header
                className={`header-two edu-header ${stickyStatus} ${styles || ""} ${
                    classes || ""
                }`}
                style={{padding: "0 200px"}}
            >
                <div className="header-row"
                     style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div className="header-logo">
                        <Link to={process.env.PUBLIC_URL + "/"}>
                            <div className="logo">
                                <a>
                                    <img
                                        className="logo-light"
                                        src="https://happy-releases.fra1.cdn.digitaloceanspaces.com/Uploads/logo.png"
                                        alt="Happy Solutions™ Logo"
                                    />
                                </a>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%'
                                }}>
                                    {false && <span
                                        style={{
                                            fontWeight: 100,
                                            fontSize: 12,
                                        }}
                                    >
                  (v{packageJson.version})
                </span>}
                                    <h4>Happy Solution<br/>
                                        <small style={{whiteSpace: "nowrap", textAlign: "center"}}>
                                            Komplex megoldások
                                        </small>
                                    </h4>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="header-nav" style={{flexGrow: 1}}>
                        <nav className="mainmenu-nav d-none d-lg-block">
                            <Nav/>
                        </nav>
                    </div>
                    <div className="header-right">
                        <div className="header-menu-bar">
                            {false && (
                                <div className="quote-icon quote-user d-none d-md-block ml--15 ml_sm--5">
                                    <button
                                        className={`edu-btn btn-medium left-icon header-button`}
                                        style={isLoggedIn ? {background: "#231f40"} : {}}
                                        onClick={() => {
                                            if (isLoggedIn) {
                                                setIsLoggedIn(false);
                                                localStorage.setItem("isLoggedIn", false);
                                                localStorage.setItem("email", "");
                                            } else setShowLoginModal(true);
                                        }}
                                    >
                                        <i className="ri-user-line"></i>
                                        {isLoggedIn ? "Kijelentkezés" : "Bejelentkezés"}
                                    </button>
                                </div>
                            )}
                        </div>
                        <div className="mobile-menu-bar ml--15 ml_sm--5 d-block d-xl-none">
                            <div className="hamberger">
                                <button
                                    className="white-box-icon hamberger-button header-menu"
                                    onClick={onCanvasHandler}
                                >
                                    <i className="ri-menu-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <ResponsiveMenu
                show={offcanvasShow}
                onClose={onCanvasHandler}
                showSearch={searchPopup}
                onSearch={onSearchHandler}
            />
        </React.Fragment>
    );
};

export default HeaderOne;