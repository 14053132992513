import React from 'react';
import SEO from '../../common/SEO';
import HeaderTwo from '../../common/header/HeaderTwo';
import BannerOne from '../../components/banner/BannerOne';
import NewsLetterFormTwo from '../../components/newsletter/NewsLetterTwo';
import DeliveryModule from "../../components/about/AboutFour";
import HappyGastro from "../detailspages/HappyGastro";
import HappyPOS from "../detailspages/HappyPOS";
import ContactUs from "../innerpages/ContactUs";
import Pricing from "../innerpages/Pricing";
import WhyChooseUs from "../../components/home-two/HomeTwoService";
import Integrations from "../../components/banner/integrations";
import FooterOne from "../../common/footer/FooterOne";
import HappyHardwares from "../../innerpages/HappyHardwares";
import HappySoftwares from "../../innerpages/HappySoftwares";
import HappyServices from "../../innerpages/HappyServices";
import DemoBanner from "../../components/banner/DemoBanner";
import ZoomableImage from "../../components/banner/ZoomableImage";
import SectionTitle from "../../components/sectionTitle/SectionTitle";
import AboutFive from "../../components/about/AboutFive";
import AboutGastro from "../../innerpages/AboutGastro";
import BannerTrade from "../../innerpages/components/BannerTrade";
import BreadcrumbOne from "../../common/breadcrumb/BreadcrumbOne";
import AboutEnergy from "../../innerpages/components/AboutEnergy";
import BannerSalon from "../../innerpages/components/BannerSalon";

const HomeThree = () => {
    const [isBannerFullScreen, setIsBannerFullScreen] = React.useState(false);

    return (
        <>
            {!isBannerFullScreen ? <><SEO title='Kezdőlap'/>
                <HeaderTwo styles="header-transparent header-style-1" fromHome={true}/>
    <BannerOne fromHome={true} isBannerFullScreen={isBannerFullScreen} setIsBannerFullScreen={setIsBannerFullScreen}/>
                <SectionTitle
                    classes = "text-center pt--30 pb--30"
                    slogan = "Nálunk megtalálja amit keres!"
                    title = "Termékeink"

                />
                <AboutGastro/>
                <BreadcrumbOne />
                <BannerTrade/>
                <BreadcrumbOne />
                <AboutEnergy/>
                <BreadcrumbOne />
                <BannerSalon/>

                    {/*<HappyServices id="happy-szolgatltatasok" fromHome={true}/>*/}

     <DemoBanner/>
     <ContactUs fromHome={true}/>
    <Integrations fromHome={true}/>

    <NewsLetterFormTwo fromHome={true}/>

    <FooterOne/></> : <ZoomableImage isBannerFullScreen={isBannerFullScreen} setIsBannerFullScreen={setIsBannerFullScreen}/>
            }


        </>
    )
}

export default HomeThree;
